import { CataloguePageType } from "../../../consts";
export var getBrowseValue = function getBrowseValue(_ref) {
  var pageType = _ref.pageType,
      pathParams = _ref.pathParams;

  if (pageType === CataloguePageType.BRAND) {
    return (pathParams === null || pathParams === void 0 ? void 0 : pathParams["brandSlug"]) || "";
  }

  if (pageType === CataloguePageType.CATEGORY) {
    var categorySlug = pathParams === null || pathParams === void 0 ? void 0 : pathParams["categorySlug"];
    return categorySlug ? Array.isArray(categorySlug) ? categorySlug.join("/") : categorySlug : "";
  }

  if (pageType === CataloguePageType.SELLER) {
    return (pathParams === null || pathParams === void 0 ? void 0 : pathParams["sellerSlug"]) || "";
  }

  if (pageType === CataloguePageType.SALES_RULE) {
    return (pathParams === null || pathParams === void 0 ? void 0 : pathParams["promoSlug"]) || "";
  }

  if (pageType === CataloguePageType.EVENT) {
    var slug = (pathParams === null || pathParams === void 0 ? void 0 : pathParams["eventSlug"]) || "";
    var eventId = slug.split("-").pop();
    return eventId || "";
  }
};